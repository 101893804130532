<div class="actions">
  <div class="change-view d-flex mb-3 justify-content-end align-items-center">
    <div class="flex ai-c gap-3">

      <button *ngIf="showActions?.includes('back')" class="btn btn-pill btn-air-light btn-outline-light txt-dark"
          [routerLink]="'/class-assigning/teacher'">{{'Forms.Back' | translate}}
      </button>
      
      <button *ngIf="showActions?.includes('add')" class="btn btn-pill btn-primary" [routerLink]="routeUrl+'/add'">{{'Forms.Add New' | translate}}</button>
      
      <button *ngIf="showActions?.includes('export')" type="button" (click)="exportDataToExcelSheet('tableToExport', fileName)" class="btn btn-light" tooltip="{{'Export' | translate}}">
        <i style="font-size: 1.25rem;" class="pi pi-file-export"></i>
      </button>

    </div>
  </div>
</div>

<div class="card">
  <p-table styleClass="p-datatable-gridlines" [columns]="displayColumns" [rows]="5" [paginator]="true"
    [rowsPerPageOptions]="[5, 10, 20]" [value]="dataList" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ 'Forms.' + col.header | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="border-bottom: 1px solid #ccc;" *ngFor="let column of columns;trackBy: trackByFn">

          <ng-container style="max-width: 5rem;" *ngIf="column.type == 'text';">
            {{ rowData[column.field] || '---' }}
          </ng-container>

          <ng-container style="max-width: 5rem;" *ngIf="column.type === 'object'">
            {{ rowData[column.field][column.sub] || '-' }}
            <!-- {{log(rowData) }} -->
          </ng-container>

          <ng-container *ngIf="column.type === 'img'">
            <a *ngIf="rowData[column.field]" href="{{baseImgUrl + rowData[column.field]}}" target="_blank">
              <img src="{{baseImgUrl + rowData[column.field]}}" width="60px" height="60px" class="img-thumbnail" alt="">
            </a>
            <span *ngIf="!rowData[column.field]">---</span>
          </ng-container>


          <ng-container *ngIf="column.type == 'action'">
            <a *ngIf="column.actions.includes('edit')" [routerLink]="['edit', rowData['id']]">
              <button pButton pRipple type="button" icon="pi pi-file-edit"
                class="p-button-rounded p-button-text p-button-dark" tooltip="{{'Forms.Edit' | translate}}">
              </button>
            </a>
            <a *ngIf="column.actions.includes('transactions') && (rowData['payment_status'] !== 'مدفوعة' && rowData['payment_status'] !== 'Paid')"
              [routerLink]="['transactions', rowData['id']]">
              <button pButton pRipple type="button" icon="pi pi-credit-card"
                class="p-button-rounded p-button-text p-button-dark" tooltip="{{'Forms.Transactions' | translate}}">
              </button>
            </a>
            <a *ngIf="column.actions.includes('edit-contact-officers') &&  rowData['contact_officers'].length"
              [routerLink]="['update-contact', rowData['id']]">
              <button pButton pRipple type="button" icon="pi pi-user-edit"
                class="p-button-rounded p-button-text p-button-dark"
                tooltip="{{'Forms.Edit Contact Officers' | translate}}">
              </button>
            </a>
            <a *ngIf="column.actions.includes('export')" (click)="downloadReceipt(rowData)">
            <button pButton pRipple type="button" icon="pi pi-file-export"
              class="p-button-rounded p-button-text p-button-dark"
              tooltip="{{'Export' | translate}}">
            </button>
          </a>
          </ng-container>

        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-table class="d-none" styleClass="p-datatable-gridlines" [columns]="displayColumns" [paginator]="false" [value]="dataToExport" [tableStyle]="{ 'min-width': '50rem' }" id="tableToExport">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ 'Forms.' + col.header | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="border-bottom: 1px solid #ccc;" *ngFor="let column of columns;trackBy: trackByFn">

          <ng-container style="max-width: 5rem;" *ngIf="column.type == 'text';">
            {{ rowData[column.field] || '---' }}
          </ng-container>

          <ng-container style="max-width: 5rem;" *ngIf="column.type === 'object'">
            {{ rowData[column.field][column.sub] || '-' }}
            <!-- {{log(rowData) }} -->
          </ng-container>

          <ng-container *ngIf="column.type === 'img'">
            <a *ngIf="rowData[column.field]" href="{{baseImgUrl + rowData[column.field]}}" target="_blank">
              <img src="{{baseImgUrl + rowData[column.field]}}" width="60px" height="60px" class="img-thumbnail" alt="">
            </a>
            <span *ngIf="!rowData[column.field]">---</span>
          </ng-container>


          <ng-container *ngIf="column.type == 'action'">
            <a *ngIf="column.actions.includes('edit')" [routerLink]="['edit', rowData['id']]">
              <button pButton pRipple type="button" icon="pi pi-file-edit"
                class="p-button-rounded p-button-text p-button-dark" tooltip="{{'Forms.Edit' | translate}}">
              </button>
            </a>
            <a *ngIf="column.actions.includes('transactions') && (rowData['payment_status'] !== 'مدفوعة' && rowData['payment_status'] !== 'Paid')"
              [routerLink]="['transactions', rowData['id']]">
              <button pButton pRipple type="button" icon="pi pi-credit-card"
                class="p-button-rounded p-button-text p-button-dark" tooltip="{{'Forms.Transactions' | translate}}">
              </button>
            </a>
            <a *ngIf="column.actions.includes('edit-contact-officers') &&  rowData['contact_officers'].length"
              [routerLink]="['update-contact', rowData['id']]">
              <button pButton pRipple type="button" icon="pi pi-user-edit"
                class="p-button-rounded p-button-text p-button-dark"
                tooltip="{{'Forms.Edit Contact Officers' | translate}}">
              </button>
            </a>
            <a *ngIf="column.actions.includes('export')" (click)="downloadReceipt(rowData)">
            <button pButton pRipple type="button" icon="pi pi-file-export"
              class="p-button-rounded p-button-text p-button-dark"
              tooltip="{{'Export' | translate}}">
            </button>
          </a>
          </ng-container>

        </td>
      </tr>
    </ng-template>
  </p-table>

</div>

<receipt [receiptDetails]="transactionDetails"></receipt>
